import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const KamsContainer = () => import('@/containers/KamsContainer')
const TheAdminContainer = () => import('@/containers/TheAdminContainer')
const TheAdminGraphsContainer = () => import('@/containers/TheAdminGraphsContainer')

const Login = () => import('@/pages/application/login/Login')


// Views
const Home = () => import('@/pages/application/Home')
const AdminHome = () => import('@/pages/administrator/AdminHome')
const AdminVisits = () => import('@/pages/administrator/AdminVisits')

const AdminContracts = () => import('@/pages/administrator/AdminContracts')
const AdminContract = () => import('@/pages/administrator/AdminContract')

const AdminCollaborators = () => import('@/pages/administrator/AdminCollaborators')
const AdminCollaborator = () => import('@/pages/administrator/AdminCollaborator')

//Kams
const KamHome = () => import('@/pages/kams/KamHome')
const KamCustomer = () => import('@/pages/kams/Customer')
const KamRuta = () => import('@/pages/kams/Ruta')
const KamRutaGenerico = () => import('@/pages/kams/GenericRuta')
const KamUniverso = () => import('@/pages/kams/Universo')
const KamVisit = () => import('@/pages/kams/Visit')

//Admins graphics
const GraphsHome = () => import('@/pages/administrator/GraphsHome')
const GraphsHome2 = () => import('@/pages/administrator/GraphsHome2')
const GraphsKamsSupports = () => import('@/pages/administrator/GraphsKamsSupports')
const GraphsKamsBoss = () => import('@/pages/administrator/GraphsKamsBoss')
const GenericsBranches = () => import('@/pages/administrator/GenericsBranches')
const UniverseCustomers = () => import('@/pages/administrator/UniverseCustomers')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/home',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Inicio',
          component: Home
        },
        {
          path: '/home/:type',
          name: 'Inicio',
          component: Home
        },
        {
          path: '/home/:type/:date',
          name: 'Inicio',
          component: Home
        }
      ]
    },
    {
      path: '/',
      name: 'Admin Home',
      component: TheAdminContainer,
      children: [
        {
          path: 'control',
          name: 'Admin Inicio',
          component: AdminContracts
        },
        {
          path: 'contracts',
          name: 'Contratos',
          component: AdminContracts
        },
        {
          path: 'contract/visits',
          name: 'Visitas',
          component: AdminVisits
        },
        {
          path: 'contract/:id/:tab',
          name: 'Visitas',
          component: AdminContract
        },
        {
          path: 'collaborators',
          name: 'Promotores',
          component: AdminCollaborators
        },
        {
          path: 'collaborator/:id/:tab',
          name: 'Visitas del Colaborador',
          component: AdminCollaborator
        },        
      ] 
    },
    {
      path: '/',
      name: 'Admin Graphs',
      component: TheAdminGraphsContainer,
      children: [        
        {
          path: 'graphs/general',
          name: 'Graficas',
          component: GraphsHome
        },
        {
          path: 'graphs/work-pending',
          name: 'Graficas',
          component: GraphsHome2
        },
        {
          path: 'graphs/kams-supports',
          name: 'Graficas',
          component: GraphsKamsSupports
        },        
        {
          path: 'graphs/kams-boss',
          name: 'Graficas',
          component: GraphsKamsBoss
        },    
        {
          path: 'graphs/generics-branches',
          name: 'Graficas',
          component: GenericsBranches
        },       
        {
          path: 'graphs/customer-contracts',
          name: 'Graficas',
          component: UniverseCustomers
        },       
      ] 
    },
    {
      path: '/',
      name: 'Kam Home',
      component: KamsContainer,
      children: [
        {
          path: 'welcome',
          name: 'Kams Inicio',
          component: KamHome
        },
        {
          path: 'customer/:id',
          name: 'Cliente',
          component: KamCustomer
        },
        {
          path: 'ruta/:id',
          name: 'Ruta Nacional',
          component: KamRuta
        },
        {
          path: 'ruta/generico/:id',
          name: 'Ruta Nacional',
          component: KamRutaGenerico
        },
        {
          path: 'universo/:id',
          name: 'Universo',
          component: KamUniverso
        },
        {
          path: 'visita/:id',
          name: 'Visita',
          component: KamVisit
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    }
  ]
})